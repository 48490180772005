<template>
  <div class="service-system">
    <v-banner-box :data="swiperData" />
    <v-nav-bar />
    <v-product-overview :data="overviewData" />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="scenarios"
    >
      <v-app-scenarios />
    </div>
    <v-industry />
    <div
      class="banner100vw banner100vw-20p30t banner100vw-linear-gradient"
      id="frame"
    >
      <v-frame />
    </div>
    <v-technology />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        productadvantage-bg
      "
      id="advantage"
    >
      <v-productadvantage />
    </div>
    <v-app-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  产品中心
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vProductOverview from './components/productOverview/index.vue'
import vAppScenarios from './components/appScenarios/index.vue'
import vIndustry from './components/industry/index.vue'
import vFrame from './components/frame/index.vue'
import vTechnology from './components/technology/index.vue'
import vProductadvantage from './components/productadvantage/index.vue'
import vAppStories from './components/appStories/index.vue'
export default {
  components: {
    vNavBar,
    vBannerBox,
    vProductOverview,
    vAppScenarios,
    vIndustry,
    vFrame,
    vTechnology,
    vProductadvantage,
    vAppStories
  },
  data () {
    return {
      swiperData: [],
      overviewData: {
        title: '交易服务系统（PC&移动端）',
        content: [
          '支撑交易过程全流程，搭建起交易流程的“框架”，各类不同交易方式的项目均在该流程框架下运行，实现交易流程全电子化管理，交易流程“框架”内可支持各大流程节点下子流程管理，方便各类用户实现业务诉求。',
          '用户可以使用移动端方便查看项目进展情况，并进行简单操作。'
        ],
        img: '/static/img/theSolution/trading-system.jpg'
      },
      caseList: []
    }
  },
  methods: {
    freeTrial () {
      console.log('免费试用')
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['交易服务系统']
      }
      this.swiperData = []
      this.$api.getBannerInfo(params).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          return console.error(res)
        }
        this.swiperData = res.data
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    },
    // 获取案例 - 交易服务系统
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['交易服务系统']
      }
      this.$api.getCaseInfo(params).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          return console.error(res)
        }
        this.caseList = res.data
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
  }
}
</script>

<style lang="less" scoped>
.service-system {
  .banner100vw();
  .productadvantage-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
